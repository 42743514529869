body {
  font-family: sans-serif;
  padding:0;
  margin: 0;
  touch-action: none;
}

#emoji-button, #text-button{
  border: none;
  background: none;
  cursor: pointer;
}

#emoji-picker {
  position: relative;
}

#emoji-widget{
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: none;
}

#canvas-editor {
  position: relative;
}

#canvas-editor canvas{
  z-index: 1;
  display: block;
}

#canvas-editor > #emoji-picker {
  position: absolute;
  bottom: 40px;
  margin: 20px;
  left: 0;
  z-index: 2;
}

#canvas-editor > #text-creator {
  position: absolute;
  bottom: 40px;
  margin: 20px;
  left: 40px;
  z-index: 2;
}

#canvas-editor > #download {
  position: absolute;
  bottom: 40px;
  right: 0;
  margin: 20px;
  z-index: 1;
}

#canvas-editor > #share {
  position: absolute;
  bottom: 65px;
  right: 0;
  margin: 20px;
  z-index: 1;
}

.canvas-video-player{
  position: absolute;
  top: 0;
  left: 0;
}
