body {
  touch-action: none;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#emoji-button, #text-button {
  cursor: pointer;
  background: none;
  border: none;
}

#emoji-picker {
  position: relative;
}

#emoji-widget {
  z-index: 999;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

#canvas-editor {
  position: relative;
}

#canvas-editor canvas {
  z-index: 1;
  display: block;
}

#canvas-editor > #emoji-picker {
  z-index: 2;
  margin: 20px;
  position: absolute;
  bottom: 40px;
  left: 0;
}

#canvas-editor > #text-creator {
  z-index: 2;
  margin: 20px;
  position: absolute;
  bottom: 40px;
  left: 40px;
}

#canvas-editor > #download {
  z-index: 1;
  margin: 20px;
  position: absolute;
  bottom: 40px;
  right: 0;
}

#canvas-editor > #share {
  z-index: 1;
  margin: 20px;
  position: absolute;
  bottom: 65px;
  right: 0;
}

.canvas-video-player {
  position: absolute;
  top: 0;
  left: 0;
}

.range-slider__container {
  flex-grow: 1;
  margin: 8px 24px 8px 8px !important;
}

.range-slider__point:nth-of-type(odd) {
  border-radius: 4px;
  width: 8px !important;
}

.range-slider__container {
  margin-bottom: 100px;
  position: relative;
}

.range-slider__container span {
  display: inline-block;
}

.range-slider__rail {
  cursor: pointer;
  width: 100%;
  position: absolute;
  left: 0;
  transform: translateY(-50%);
}

.range-slider__track {
  cursor: pointer;
  position: absolute;
  transform: translateY(-50%);
}

.range-slider__point {
  cursor: pointer;
  border-radius: 50%;
  transition: box-shadow .15s;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
}

.range-slider__container .range-slider__tooltip {
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background-color: gray;
  border-radius: 3px;
  min-width: 30px;
  padding: .3em .6em;
  font-size: 16px;
  transition: transform .15s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: -100%;
  left: 0;
  transform: translate(-50%, -50%)scale(0);
}

.range-slider__container .range-slider__tooltip:after {
  content: "";
  z-index: -1;
  background-color: gray;
  width: 1em;
  height: 1em;
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translate(-50%)rotate(45deg);
}

.canvas-video-player-controls {
  z-index: 1;
  background: #ffffff7d;
  align-items: center;
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.canvas-video-player-play {
  cursor: pointer;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjUgNS4wMDAxMkM5LjMyODQzIDUuMDAwMTIgMTAgNS42NzE2OSAxMCA2LjUwMDEyVjE3LjUwMDFDMTAgMTguMzI4NSA5LjMyODQzIDE5LjAwMDEgOC41IDE5LjAwMDFDNy42NzE1NyAxOS4wMDAxIDcgMTguMzI4NSA3IDE3LjUwMDFWNi41MDAxMkM3IDUuNjcxNjkgNy42NzE1NyA1LjAwMDEyIDguNSA1LjAwMDEyWiIgZmlsbD0iIzAwOUNGRiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE1LjUgNS4wMDAwNkMxNi4zMjg0IDUuMDAwMDYgMTcgNS42NzE2MyAxNyA2LjUwMDA2VjE3LjUwMDFDMTcgMTguMzI4NSAxNi4zMjg0IDE5LjAwMDEgMTUuNSAxOS4wMDAxQzE0LjY3MTYgMTkuMDAwMSAxNCAxOC4zMjg1IDE0IDE3LjUwMDFWNi41MDAwNkMxNCA1LjY3MTYzIDE0LjY3MTYgNS4wMDAwNiAxNS41IDUuMDAwMDZaIiBmaWxsPSIjMDA5Q0ZGIi8+Cjwvc3ZnPgo=") 0 0 / contain;
  border: none;
  outline: none;
  width: 32px;
  height: 32px;
  margin: 8px;
}

.canvas-video-player-play.pause {
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAxNS4xMDIxTDE0Ljg2NzEgMTJMMTAgOC44OTc5M1YxNS4xMDIxWk04IDYuNzUxMTlWMTcuMjQ4OEM4IDE3Ljg2NjcgOC44MzAwOSAxOC4yMTk0IDkuNDExNzYgMTcuODQ4N0wxNy42NDcxIDEyLjU5OTlDMTguMTE3NiAxMi4yOTk5IDE4LjExNzYgMTEuNzAwMSAxNy42NDcxIDExLjQwMDFMOS40MTE3NiA2LjE1MTMzQzguODMwMDkgNS43ODA1OSA4IDYuMTMzMyA4IDYuNzUxMTlaIiBmaWxsPSIjQkFDOURFIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4xODM3IDE4LjQxMzlDNi44MjcwMiAxNy44MjI5IDcuMDE3MDQgMTcuMDU0NiA3LjYwODEyIDE2LjY5NzlMNy42MDM2OCAxMi4wMzQ4TDcuNjAzNjggNy4zMDgxQzcuMDE0MTMgNi45NDg4OSA2LjgyNzQgNi4xNzk3OCA3LjE4NjYxIDUuNTkwMjNDNy41NDU4MSA1LjAwMDY5IDguMzE0OTIgNC44MTM5NiA4LjkwNDQ3IDUuMTczMTZMMTguNDA5OCAxMC45NjQ2QzE4Ljc4MyAxMS4xOTIgMTkuMDEwMyAxMS41OTc4IDE5LjAwOTQgMTIuMDM0OEMxOS4wMDg1IDEyLjQ3MTggMTguNzc5NCAxMi44NzY2IDE4LjQwNTIgMTMuMTAyM0w4Ljg5OTc3IDE4LjgzODNDOC4zMDg2OSAxOS4xOTUgNy41NDAzOCAxOS4wMDUgNy4xODM3IDE4LjQxMzlaIiBmaWxsPSIjMDA5Q0ZGIi8+CjxwYXRoIGQ9Ik04LjI1NCA2LjI0MDZMOC4yNTM5MSAxNy41MjQzIiBzdHJva2U9IiMwMDlDRkYiIHN0cm9rZS13aWR0aD0iMi41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") 0 0 / contain no-repeat;
}

#text-widget {
  background: #fff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: min(80vw, 400px);
  height: 200px;
  padding: 40px;
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#text-options {
  justify-content: center;
  align-items: center;
  gap: 20px;
  display: flex;
}

#text-input {
  text-align: center;
  background: none;
  border: none;
  outline: none;
  font-size: 48px;
}
/*# sourceMappingURL=index.39c7f12f.css.map */
