.canvas-video-player-controls{
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	background: #ffffff7d;
  }

.canvas-video-player-play{
	margin:8px;
	width: 32px;
	height: 32px;
	border: none;
	outline: none;
	cursor: pointer;
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik04LjUgNS4wMDAxMkM5LjMyODQzIDUuMDAwMTIgMTAgNS42NzE2OSAxMCA2LjUwMDEyVjE3LjUwMDFDMTAgMTguMzI4NSA5LjMyODQzIDE5LjAwMDEgOC41IDE5LjAwMDFDNy42NzE1NyAxOS4wMDAxIDcgMTguMzI4NSA3IDE3LjUwMDFWNi41MDAxMkM3IDUuNjcxNjkgNy42NzE1NyA1LjAwMDEyIDguNSA1LjAwMDEyWiIgZmlsbD0iIzAwOUNGRiIvPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTE1LjUgNS4wMDAwNkMxNi4zMjg0IDUuMDAwMDYgMTcgNS42NzE2MyAxNyA2LjUwMDA2VjE3LjUwMDFDMTcgMTguMzI4NSAxNi4zMjg0IDE5LjAwMDEgMTUuNSAxOS4wMDAxQzE0LjY3MTYgMTkuMDAwMSAxNCAxOC4zMjg1IDE0IDE3LjUwMDFWNi41MDAwNkMxNCA1LjY3MTYzIDE0LjY3MTYgNS4wMDAwNiAxNS41IDUuMDAwMDZaIiBmaWxsPSIjMDA5Q0ZGIi8+Cjwvc3ZnPgo=");
	background-size: contain;
}

.canvas-video-player-play.pause{
	background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMCAxNS4xMDIxTDE0Ljg2NzEgMTJMMTAgOC44OTc5M1YxNS4xMDIxWk04IDYuNzUxMTlWMTcuMjQ4OEM4IDE3Ljg2NjcgOC44MzAwOSAxOC4yMTk0IDkuNDExNzYgMTcuODQ4N0wxNy42NDcxIDEyLjU5OTlDMTguMTE3NiAxMi4yOTk5IDE4LjExNzYgMTEuNzAwMSAxNy42NDcxIDExLjQwMDFMOS40MTE3NiA2LjE1MTMzQzguODMwMDkgNS43ODA1OSA4IDYuMTMzMyA4IDYuNzUxMTlaIiBmaWxsPSIjQkFDOURFIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNy4xODM3IDE4LjQxMzlDNi44MjcwMiAxNy44MjI5IDcuMDE3MDQgMTcuMDU0NiA3LjYwODEyIDE2LjY5NzlMNy42MDM2OCAxMi4wMzQ4TDcuNjAzNjggNy4zMDgxQzcuMDE0MTMgNi45NDg4OSA2LjgyNzQgNi4xNzk3OCA3LjE4NjYxIDUuNTkwMjNDNy41NDU4MSA1LjAwMDY5IDguMzE0OTIgNC44MTM5NiA4LjkwNDQ3IDUuMTczMTZMMTguNDA5OCAxMC45NjQ2QzE4Ljc4MyAxMS4xOTIgMTkuMDEwMyAxMS41OTc4IDE5LjAwOTQgMTIuMDM0OEMxOS4wMDg1IDEyLjQ3MTggMTguNzc5NCAxMi44NzY2IDE4LjQwNTIgMTMuMTAyM0w4Ljg5OTc3IDE4LjgzODNDOC4zMDg2OSAxOS4xOTUgNy41NDAzOCAxOS4wMDUgNy4xODM3IDE4LjQxMzlaIiBmaWxsPSIjMDA5Q0ZGIi8+CjxwYXRoIGQ9Ik04LjI1NCA2LjI0MDZMOC4yNTM5MSAxNy41MjQzIiBzdHJva2U9IiMwMDlDRkYiIHN0cm9rZS13aWR0aD0iMi41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") no-repeat;
	background-size: contain;
}
