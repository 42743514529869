.range-slider__container {
	margin: 8px 24px 8px 8px !important;
	flex-grow: 1;
  }
  
  .range-slider__point:nth-of-type(2n + 1) {
	width: 8px !important;
	border-radius: 4px;
  }
  
  .range-slider__container{
	margin-bottom: 100px;
  }
  
  .range-slider__container{
	position: relative;
  }
  
  .range-slider__container span{
	display: inline-block;
  }
  
  .range-slider__rail {
	width: 100%;
	position: absolute;
	transform: translateY(-50%);
	left: 0;
  
	cursor: pointer;
  }
  
  .range-slider__track {
	position: absolute;
	transform: translateY(-50%);
	cursor: pointer;
  }
  
  .range-slider__point {
	top: 0;
	transform: translateX(-50%);
	position: absolute;
	border-radius: 50%;
	cursor: pointer;
	transition: box-shadow 150ms;
  }
  
  .range-slider__container .range-slider__tooltip {
	min-width: 30px;
	font-size: 16px;
	padding: 0.3em 0.6em;
	background-color: gray;
	color: white;
	position: absolute;
	left: 0;
	top: -100%;
	text-align: center;
	border-radius: 3px;
	user-select: none;
	transform: translate(-50%, -50%) scale(0);
	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .range-slider__container .range-slider__tooltip::after {
	content: '';
	background-color: gray;
	width: 1em;
	height: 1em;
	position: absolute;
	bottom: -5px;
	transform: translate(-50%) rotate(45deg);
	left: 50%;
	z-index: -1;
  }
