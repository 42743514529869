#text-widget{
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: min(80vw, 400px);
	height: 200px;
	background: #ffffff;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 20px;
	padding: 40px;
}

#text-options{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 20px;
}

/* reset default input styles */
#text-input{
	font-size: 48px;
	text-align: center;
	background:none;
	border:none;
	outline:none;
}
